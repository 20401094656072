import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { get, canUseDOM } from '../../lib/getPropertyByPath';
import { useAppStateContext } from '../../lib/AppStateProvider';
import Hjson from 'hjson';
import cssVars from 'css-vars-ponyfill';
import { getLatestUserCookie } from '@dtcm/ui-components/dist/components/Login/user_session';
import { fetchData } from '@dtcm/ui-components/dist/utils/fetchData';
import { getColorByTheme } from '@dtcm/ui-components/dist/utils/index';
import { SHA256 } from '@dtcm/ui-components/dist/utils/sha256';
import PreloadImages from './PreloadImages';

export interface MainContainerProps extends RouteComponentProps {
  context: {
    origin: string;
    language: string;
    tealium: {
      originUrl: string;
      environment: string;
    };
    canonical: string;
  };
  route: {
    name: string;
    itemId: string;
    templateName: string;
    fields: {
      browserTitle: {
        value: string;
      };
    };
  };
}
const isInViewport = (el: any) => {
  if (!el) {
    return false;
  }
  var top = el.offsetTop;
  var left = el.offsetLeft;
  var width = el.offsetWidth;
  var height = el.offsetHeight;

  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  return (
    top < window.pageYOffset + window.innerHeight &&
    left < window.pageXOffset + window.innerWidth &&
    top + height > window.pageYOffset &&
    left + width > window.pageXOffset
  );
};

const resolvePageTitle = (route: any) => {
  let pageTitle = get(route, 'fields.browserTitle.value');
  if (!pageTitle || pageTitle === '$name') {
    pageTitle = get(route, 'fields.Title.value');
  }
  return pageTitle;
};

const resolveMetaDescription = (route: any) => {
  let desc = get(route, 'fields.metaDescription.value');
  if (!desc) {
    desc = get(route, 'fields.Intro Text.value');
  }
  return desc;
};
const resolveMetaImage = (route: any) => {
  let desc = get(route, 'fields.Fallback image.value.src', '');

  return desc;
};

const getOriginUrl = (context: any) => {
  let origin = get(context, 'origin');
  if (origin.endsWith('/')) {
    origin += '/';
  }
  return origin;
};

/*const resolveCanonicalUrl = (context: any, location: any) => {
  const path = location && location.pathname ? location.pathname : '/';
  return `${getOriginUrl(context)}${path}`;
};
*/
const renderProperties = (prefix: string, queryString: string) => {
  if (!queryString) {
    return null;
  }
  if (!prefix) {
    return null;
  }
  const properties = [];
  const parts = queryString.split('&');
  for (let i = 0; i < parts.length; i++) {
    const pair = parts[i].split('=');
    properties.push(
      <meta property={`${prefix}:${decodeURIComponent(pair[0])}`} content={decodeURIComponent(pair[1])} />
    );
  }

  return properties;
};

const getAlternateUrls = (context: any) => {
  const alternateUrls = get(context, 'alternateUrls');
  if (!alternateUrls) {
    return null;
  }
  const alternateUrlTags = alternateUrls.map((alternateUrl: any) => (
    <link
      key={alternateUrl.lang}
      rel="alternate"
      href={`${getOriginUrl(context)}${alternateUrl.url}`}
      hrefLang={alternateUrl.lang === 'zh' ? 'zh-hans' : alternateUrl.lang}
    />
  ));
  // alternateUrlTags.push(
  //   <link rel="alternate" href={resolveCanonicalUrl(context, location)} hrefLang="x-default" />
  // );
  return alternateUrlTags;
};

const getXDefault = (context: any) => {
  const alternateUrls = get(context, 'alternateUrls');
  if (!alternateUrls) {
    return null;
  }
  const alternateUrlTags = alternateUrls.map((alternateUrl: any) => {
    if (alternateUrl.lang === 'en') {
      return (
        <link
          key={alternateUrl.lang}
          rel="alternate"
          href={`${getOriginUrl(context)}${alternateUrl.url}`}
          hrefLang="x-default"
        />
      );
    } else {
      return '';
    }
  });

  return alternateUrlTags;
};

const MainContainer: React.FunctionComponent<MainContainerProps> = ({ route, location, context }) => {
  const pageTitle = resolvePageTitle(route);
  const pageDescription = resolveMetaDescription(route);
  const pageImage = context.origin + resolveMetaImage(route).split('?')[0];
  const pageUrl = get(context, 'canonical');
  const ogTitle = get(route, 'fields.og-title.value');
  const ogDescription = get(route, 'fields.og-description.value');
  const ogImage = get(route, 'fields.og-image.value.src');
  const twitterTitle = get(route, 'fields.twitter-title.value');
  const twitterDescription = get(route, 'fields.twitter-description.value');
  const [firstLoad, setFirstLoad] = React.useState<Boolean>(true);
  const [articleListSchema, setArticleListSchema] = React.useState<string>('');
  const [FAQStructuredData, setFAQStructuredData] = React.useState<any>();

  const { itemId, templateName } = route;
  const { language, tealium } = context;
  const browserLanguage = typeof navigator !== 'undefined' ? navigator.language : '';

  const utagsData = get(context, 'utags.script', '');
  const utagsDataString = utagsData.split('utag_data = ')[1];

  const utagsDataObj = Hjson.parse(utagsDataString);

  const utag_data = {
    ...utagsDataObj,
    page_id: itemId,
    page_name: templateName,
    page_language: language,
    template: templateName,
    pillar: get(route, 'fields.pillar.value'),
    content_type: get(route, 'fields.contentType.value'),
    persona: get(route, 'fields.persona.value'),
    page_location: pageUrl,
    page_title: pageTitle,
    page_path: location.pathname,
    page_browser_language: browserLanguage,
    session_country: get(context, 'utags.script', '').includes('session_country')
      ? get(context, 'utags.script', '')
          .split('session_country:')[1]
          .replace(/[\W_]+/g, ' ')
          .trim()
      : '',
    session_country_code: get(context, 'utags.script', '').includes('session_country_code')
      ? get(context, 'utags.script', '')
          .split('session_country_code:')[1]
          .split(',')[0]
          .replace(/[\W_]+/g, ' ')
          .trim()
      : '',
  };
  if (!tealium) {
    console.warn('tealium settings are not provided');
  }

  useEffect(() => {
    const wind: any = window;
    if (wind) {
      wind.utag_data = {
        ...wind.utag_data,
        ...utag_data,
      };
    }
    // if cust_id absent in global varible
    if (!get(wind, 'utag_data.cust_id')) {
      const utag_data = document.getElementById('utag_data');
      if (utag_data && utag_data.innerHTML) {
        const startIndex = utag_data.innerHTML.indexOf('{');
        const data = Hjson.parse(utag_data.innerHTML.slice(startIndex));
        // if cust_id exist in html but absent in global variable - just set it, otherwise - get from cookie
        if (data.cust_id) {
          wind.utag_data.cust_id = data.cust_id;
        } else {
          getLatestUserCookie().then((userCookie: any) => {
            if (userCookie.access_token) {
              const apiGlobal =
                get(appState, 'sitecoreContextData.configuration.globalApi.apiGlobal') ||
                'https://apiqa.testvisitdubai.com';
              fetchData({
                path: `${apiGlobal}/graphql/user`,
                options: {
                  method: 'POST',
                  body: JSON.stringify({
                    query: `{ userProfile { email } }`,
                  }),
                },
                authToken: userCookie.access_token,
              }).then((data: any) => {
                const { email: ddd } = data.data.userProfile;
                const encrEmail = SHA256(ddd);
                if (wind && wind.utag_data) {
                  wind.utag_data.cust_id = encrEmail;
                }
                const utag_data = document.getElementById('utag_data');
                if (utag_data && utag_data.innerHTML) {
                  const startIndex = utag_data.innerHTML.indexOf('{');
                  const data = Hjson.parse(utag_data.innerHTML.slice(startIndex));
                  data.cust_id = encrEmail;
                  utag_data.innerHTML = `var utag_data = ${JSON.stringify(data)}`;
                }
              });
            }
          });
        }
      }
    }
  }, [route.name]);
  useEffect(() => {
    const pageBackground = get(context, 'configuration.page.background');

    if (canUseDOM) {
      document.body.classList.remove('bg-1', 'bg-2', 'bg-3');
      //   if (pageBackground === 'Default') {

      //     document.body.classList.remove("bg-1","bg-2","bg-3");
      //   }
      //  else
      if (pageBackground === 'Background 1') {
        document.body.classList.add('bg-1');
      } else if (pageBackground === 'Background 2') {
        document.body.classList.add('bg-2');
      }
      if (pageBackground === 'Background 3') {
        document.body.classList.add('bg-3');
      }
    }
  }, [route.name]);
  useEffect(() => {
    const wind: any = window;
    const utag = wind.utag;

    if (wind && utag) {
      utag.data = {
        ...utag.data,
        ...utag_data,
      };
      wind.utag_data = {
        ...wind.utag_data,
        ...utag_data,
      };

      wind.utag.utag_cfg_ovrd = wind.utag.utag_cfg_ovrd || {}; // Make sure we don't kill a previous decleration of the object
      wind.utag.utag_cfg_ovrd.noview = true;
      if (!firstLoad) {
        utag.view(wind.utag_data);
      } else {
        setFirstLoad(false);
      }
    }
  }, [route.name]);

  useEffect(() => {
    const moduels = get(route, 'placeholders.leisure-root', []);
    let scrolledto25 = false;
    let scrollTimer: any = 0;
    let scrollTimer2: any = 0;
    let timer: any = 0;

    let timerLogged = false;
    let timerLogged2 = false;
    let scrolledto50 = false;
    let scrolledto75 = false;
    let scrolledto100 = false;

    let timer20Finsihed = false;
    let timer50Finsihed = false;
    const desktopHeights: any = {
      NeighborhoodsMap: {
        desktop: 1150,
        mobile: 850,
      },
      Map: {
        desktop: 790,
        mobile: 709,
      },
      Curators: {
        desktop: 810,
        mobile: 780,
      },
      Itineraries: {
        desktop: 610,
        mobile: 811,
      },
      ContactCard: {
        desktop: 740,
        mobile: 991,
      },
      VisaRequirementsWizard: {
        desktop: 260,
        mobile: 441,
      },
      EditableListiclePOIs: {
        desktop: 1050,
        mobile: 798,
      },
      EditableListicleEvents: {
        desktop: 1050,
        mobile: 611,
      },
      ListiclePOIs: {
        desktop: 1050,
        mobile: 670,
      },
      ListicleEvents: {
        desktop: 1050,
        mobile: 611,
      },
      ListicleMedia: {
        desktop: 950,
        mobile: 652,
      },
      ItineraryOverview: {
        desktop: 450,
        mobile: 585,
      },
      Tags: {
        desktop: 140,
        mobile: 178,
      },
      CookiePolicy: {
        desktop: 120,
        mobile: 250,
      },
      TestimonialQuote: {
        desktop: 400,
        mobile: 468,
      },
      PracticalInformation: {
        desktop: 780,
        mobile: 679,
      },
      MediaGrid: {
        desktop: 1000,
        mobile: 2040,
      },
      Footer: {
        desktop: 600,
        mobile: 1050,
      },
      CuratorItineraries: {
        desktop: 684,
        mobile: 816,
      },
      CuratorPOIs: {
        desktop: 681,
        mobile: 770,
      },
      CuratorEvents: {
        desktop: 450,
        mobile: 604,
      },
      DetailsCardService: {
        desktop: 369,
        mobile: 585,
      },
      MediaVideo: {
        desktop: 740,
        mobile: 350,
      },

      Timeline: {
        desktop: 763,
        mobile: 587,
      },
      MediaCarousel: {
        desktop: 795,
        mobile: 380,
      },
      SocialSharing: {
        desktop: 126,
        mobile: 146,
      },
      TestimonialMultipleMedia: {
        desktop: 414,
        mobile: 663,
      },
      TestimonialMedia: {
        desktop: 414,
        mobile: 663,
      },
      WizardTraining: {
        desktop: 654,
        mobile: 562,
      },
      DownloadSingle: {
        desktop: 242,
        mobile: 231,
      },
      AutoDownload: {
        desktop: 104,
        mobile: 104,
      },

      MediaAndCopy: {
        desktop: 456,
        mobile: 677,
      },

      AudioPlayer: {
        desktop: 136,
        mobile: 136,
      },
      FunFactsContainer: {
        desktop: 342,
        mobile: 507,
      },
      FunFactItem: {
        desktop: 342,
        mobile: 507,
      },
      Partners: {
        desktop: 270,
        mobile: 273,
      },
      ItineraryFoodBreak: {
        desktop: 360,
        mobile: 427,
      },
      TravelDeals: {
        desktop: 590,
        mobile: 894,
      },
      CuratorSection: {
        desktop: 1130,
        mobile: 1180,
      },
      BottomNavigation: {
        desktop: 224,
        mobile: 288,
      },
      ItineraryBottomNavigation: {
        desktop: 224,
        mobile: 288,
      },
      TimelineNavigation: {
        desktop: 542,
        mobile: 582,
      },
      UserGeneratedContent: {
        desktop: 358,
        mobile: 358,
      },
      CuratorMatcher: {
        desktop: 715,
        mobile: 743,
      },

      MediaImage360: {
        desktop: 780,
        mobile: 347,
      },

      ProfileCards: {
        desktop: 786,
        mobile: 766,
      },
      GeneralCardIcon: {
        desktop: 335,
        mobile: 315,
      },
      GeneralCardIconMultiple: {
        desktop: 494,
        mobile: 365,
      },
      GeneralCardImage: {
        desktop: 554,
        mobile: 571,
      },
      GeneralCardImageMultiple: {
        desktop: 738,
        mobile: 751,
      },
    };
    let scrollEvent: any = undefined;

    if (canUseDOM) {
      const deviceType = document.body.clientWidth <= 767 ? 'mobile' : 'desktop';
      timer = setTimeout(() => {
        let bodyHeightOnLoad = document.body.clientHeight + desktopHeights.Footer[deviceType];
        moduels.map((module: any) => {
          if (isHaveStructedData(module) && module.componentName === 'PracticalInformation') {
            setFAQStructuredData(createStructedData(module));
          }

          if (moduels.length === 1) {
            bodyHeightOnLoad = document.body.clientHeight;
          }
          if (module.componentName === 'ItineraryPOIsOverview') {
            const poiLength = get(module, 'fields.data.item.pois.values.length');
            bodyHeightOnLoad += desktopHeights.ListiclePOIs[deviceType] * poiLength;
          }

          if (desktopHeights[module.componentName]) {
            bodyHeightOnLoad += desktopHeights[module.componentName][deviceType];
          }
        });
        const wind: any = window;
        if (wind) {
          wind.utag_data = {
            ...wind.utag_data,
            ...utag_data,
            total_window_height: `${bodyHeightOnLoad}`,
          };
        }
        console.log('bodyHeightOnLoad', bodyHeightOnLoad);
        clearTimeout(scrollTimer);
        clearTimeout(scrollTimer2);
        let scrollPercentRounded = 0;

        scrollEvent = () => {
          let scrollTop = window.scrollY;
          let docHeight = bodyHeightOnLoad;
          let winHeight = window.innerHeight;
          let scrollPercent = scrollTop / (docHeight - winHeight);
          scrollPercentRounded = Math.round(scrollPercent * 100);

          if (scrollPercentRounded >= 25) {
            if (!scrolledto25) {
              scrolledto25 = true;

              if (get(window, 'SC_Scoll_Function')) {
                get(window, 'SC_Scoll_Function')('25');
              }
              if (timer20Finsihed) {
                if (get(window, 'SC_Scoll_Time_Function1')) {
                  get(window, 'SC_Scoll_Time_Function1')(scrollPercentRounded, '20');
                }
              }
            }
          }
          if (scrollPercentRounded >= 50) {
            if (!scrolledto50) {
              scrolledto50 = true;

              if (get(window, 'SC_Scoll_Function')) {
                get(window, 'SC_Scoll_Function')('50');
              }

              if (timer50Finsihed) {
                if (get(window, 'SC_Scoll_Time_Function1')) {
                  get(window, 'SC_Scoll_Time_Function1')(
                    scrollPercentRounded > 100 ? 100 : scrollPercentRounded,
                    '30'
                  );
                }
              }

              //   clearTimeout(scrollTimer);
            }
          }
          if (scrollPercentRounded >= 75) {
            if (!scrolledto75) {
              scrolledto75 = true;

              if (get(window, 'SC_Scoll_Function')) {
                get(window, 'SC_Scoll_Function')('75');
              }
            }
          }

          if (isInViewport(document.querySelector('.footer__bottom'))) {
            if (!scrolledto100) {
              scrolledto100 = true;

              if (get(window, 'SC_Scoll_Function')) {
                get(window, 'SC_Scoll_Function')('100');
              }
            }
          }
        };

        scrollTimer = setTimeout(() => {
          timer20Finsihed = true;
          if (!timerLogged) {
            if (scrolledto25) {
              timer20Finsihed = false;
              if (get(window, 'SC_Scoll_Time_Function1')) {
                get(window, 'SC_Scoll_Time_Function1')(scrollPercentRounded, '20');
              }

              timerLogged = true;
              clearTimeout(scrollTimer);
            }
          } else {
            clearTimeout(scrollTimer);
          }
        }, 20 * 1000);
        scrollTimer2 = setTimeout(() => {
          timer50Finsihed = true;
          if (!timerLogged2) {
            if (scrolledto50) {
              timer50Finsihed = false;
              if (get(window, 'SC_Scoll_Time_Function1')) {
                get(window, 'SC_Scoll_Time_Function1')(
                  scrollPercentRounded > 100 ? 100 : scrollPercentRounded,
                  '30'
                );
              }
              timerLogged2 = true;
              clearTimeout(scrollTimer2);
            }
          } else {
            clearTimeout(scrollTimer2);
          }
        }, 30 * 1000);
        window.addEventListener('scroll', scrollEvent);
      }, 3000);
    }
    return () => {
      window.removeEventListener('scroll', scrollEvent);
      clearTimeout(timer);

      clearTimeout(scrollTimer);
      clearTimeout(scrollTimer2);
    };
  }, [route.name]);

  useEffect(() => {
    if ((route && route.templateName === 'Article') || 'Article Listicle Page') {
      const listOfPossibleTypes = [
        'EditableListicleEvents',
        'EditableListiclePOIs',
        'ListicleEvents',
        'ListiclePOIs',
        'ListicleMedia',
      ];
      const itemsForItemList = get(route, 'placeholders.leisure-root', []).filter(
        (item: any) => listOfPossibleTypes.includes(item.componentName) && item.dataSource
      );

      if (itemsForItemList.length) {
        setArticleListSchema(
          JSON.stringify(
            itemsForItemList.map((item: any, index: number) => ({
              '@type': 'ListItem',
              position: index + 1,
              name: get(item, 'fields.data.item.title.jss.value'),
            }))
          )
        );
      } else {
        setArticleListSchema('');
      }
    }

    return () => {
      setArticleListSchema('');
    };
  }, [route.name]);

  useEffect(() => {
    if (canUseDOM) {
      if (get(route, 'fields.headTransparent.value')) {
        document.body.classList.add('nav--transparent');
      } else {
        document.body.classList.remove('nav--transparent');
      }
    }
  }, [route.name]);

  const isDubaiFont = () => {
    const languageName = get(appState, 'language.languageCode', 'en');
    const isDubaiFont = [
      'en',
      'ar',
      'fr',
      'da',
      'de',
      'fi',
      'it',
      'no',
      'pt',
      'es',
      'sv',
    ].some((value: string) => value === languageName);

    return isDubaiFont;
  };

  const { appState } = useAppStateContext();

  const theme = get(appState, 'sitecoreContextData.themesOfPage') || {};
  const themePrimary = get(theme, 'primary') || '';
  const themePrimaryActive = get(theme, 'primary_active') || '';
  const themeGreyscale = get(theme, 'greyscale.item1') || '';
  const fontVariable = isDubaiFont() ? "'Dubai', 'Arial', sans-serif" : 'Calibri, sans-serif';

  // #0067B9 --site-color-2
  cssVars({
    shadowDOM: true,
    variables: {
      '--site-color': '#035E88',
      '--site-color-2': `${themePrimaryActive ? themePrimaryActive : '#004565'}`,
      '--main-color': `${themePrimary ? themePrimary : '#035E88'}`,
      '--main-color-text': `${getColorByTheme(themePrimary ? themePrimary : '#035E88')}`,
      '--main-color--active-2': '#006699',
      '--main-color--active': `${themePrimaryActive ? themePrimaryActive : '#004565'}`,
      '--greyscale': `${themeGreyscale ? themeGreyscale : '#505050'}`,
      '--site-font-family': `${fontVariable}`,
    },
  });

  const removeImageResolution = (src: string) => {
    const queryStr = src.split('?')[1];
    const clearedQueryStr = queryStr
      ? queryStr
          .split('&')
          .filter((item) => item.indexOf('h=') != 0 && item.indexOf('w=') != 0)
          .join('&')
      : '';

    return src.split('?')[0] + '?' + clearedQueryStr;
  };

  const injectImageResolution = (src: string, width: string | number, height: string | number): string => {
    const imageSrc = removeImageResolution(src);
    return `${imageSrc}${imageSrc.match(/\?/) ? '&' : '?'}cw=${width}&ch=${height}`;
  };

  const renderArticleListSchema = () => {
    const scriptContent = `{"@context": "https://schema.org","@type": "ItemList","itemListElement": ${
      articleListSchema || []
    }}`;
    return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: scriptContent }} />;
  };

  const anyRoot: any = route;

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href={pageUrl} />
        <meta name="description" content={pageDescription} />
        <meta name="yandex-verification" content="621f8e9e78e36839" />
        <meta name="keywords" content={get(route, 'fields.metaKeywords.value')} />
        <meta
          name="robots"
          content={get(route, 'fields.Allow Google Indexing.value') ? 'index, follow' : 'noindex, nofollow'}
        />
        {false && <meta name="robots" content="noindex, nofollow" />}
        {'/* disallow google crawling on archived events */'}
        {appState.darkMode && <meta name="color-scheme" content="dark light" />}
        {get(route, 'fields.Archived.value') && <meta name="robots" content="noindex, nofollow" />}
        <meta property="og:title" content={ogTitle ? ogTitle : pageTitle} />
        <meta property="og:description" content={ogDescription ? ogDescription : pageDescription} />
        <meta property="og:type" content={get(route, 'fields.og-type.value', 'website')} />
        <meta property="og:image" content={ogImage ? ogImage : injectImageResolution(pageImage, 256, 256)} />
        <meta property="og:url" content={pageUrl} />
        <meta name="twitter:title" content={twitterTitle ? twitterTitle : pageTitle} />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content={twitterDescription ? twitterDescription : pageDescription}
        />
        <meta name="twitter:site" content={get(route, 'fields.twitter-site.value', '@visitdubai')} />
        <meta
          name="twitter:image:src"
          content={
            get(route, 'fields.twitter-image.value.src')
              ? get(route, 'fields.twitter-image.value.src')
              : pageImage
          }
        />
        <PreloadImages route={route} />
        {renderProperties('fb', get(route, 'fields.facebook-properties.value'))}
        {renderProperties('twitter', get(route, 'fields.twitter-properties.value'))}
        {getAlternateUrls(context)}
        {getXDefault(context)}
        <script id="utag_data" type="text/javascript">{`var utag_data = ${JSON.stringify(utag_data)}`}</script>
        <script type="text/javascript">{`
         var algoliaSettings={key:"${get(context, 'configuration.algolia.algoliaApiKey')}",id:"${get(
          context,
          'configuration.algolia.algoliaApplicationId'
        )}",fullTextSearchIndex:"${get(context, 'configuration.algolia.fullTextSearchIndex')}"}`}</script>
        {FAQStructuredData ? (
          <script type="application/ld+json">{JSON.stringify(FAQStructuredData)}</script>
        ) : (
          ''
        )}
      </Helmet>
      <Placeholder name="leisure-root" rendering={anyRoot} route={route} r={true} />
      <style>
        {`:root {
          --site-color: '#035E88',
          --site-color-2: '#0067B9',
          --main-color: ${themePrimary ? themePrimary : '#0067B9'};
          --main-color--active: ${themePrimaryActive ? themePrimaryActive : '#005D90'};
          --main-color--active-2: '#006699',
          --greyscale: ${themeGreyscale ? themeGreyscale : '#505050'};
        }`}
        {appState.darkMode &&
          `:root {
          color-scheme: light dark;
        }`}
      </style>
      {articleListSchema ? renderArticleListSchema() : null}
    </React.Fragment>
  );
};

export default withRouter(MainContainer);

const isHaveStructedData = (module: any) => {
  const items =
    get(module, 'fields.data.datasource.faqs') ||
    get(module, 'fields.data.item.items.targetItems') ||
    get(module, 'fields.data.item.children', []);
  return !!items.length;
};

const createStructedData = (module: any) => {
  const items =
    get(module, 'fields.data.datasource.faqs') ||
    get(module, 'fields.data.item.items.targetItems') ||
    get(module, 'fields.data.item.children', []);

  return StructuredData(
    items.map((item: any) =>
      createStructuredItem(
        get(item, 'title.field.value') || get(item, 'title.jss.value'),
        get(item, 'description.field.value') || get(item, 'description.jss.value')
      )
    )
  );
};

const createStructuredItem = (question: string, answer: string) => {
  return {
    '@type': 'Question',
    name: question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: answer.replace(/(<([^>]+)>)/gi, ''),
    },
  };
};

const StructuredData = (mainEntity: Array<any> = []) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: mainEntity,
  };
};
